export default function loadFogbender() {
  !(function() {
    var e = Object.assign(
        {},
        {
          _queue: [],
          _once: !1,
          _load: function(e, o) {
            n.setClientUrl(e)
            var r = document.createElement('script')
            ;(r.type = 'text/javascript'),
              (r.async = !0),
              (r.src = e + '/loader.js'),
              (r.onload = o)
            var t = document.getElementsByTagName('script')[0]
            t.parentNode.insertBefore(r, t)
          },
        }
      ),
      n = new Proxy(e, {
        get: function(e, n) {
          var o = e._fogbender
          return '_' === n[0]
            ? e[n]
            : o
            ? o[n]
            : function() {
                var o = arguments
                return new Promise(function(r, t) {
                  e._queue.push([n, o, r, t])
                })
              }
        },
      }),
      o = window,
      r = (o.fogbender = o.fogbender || n)
    r._once
      ? window.console && console.error && console.error('Fogbender snippet included twice.')
      : ((r._once = !0), r.setVersion('snippet', '0.1.0'))
  })()

  // document.head.appendChild(script)
}
