<template>
  <Login v-if="showLogin" :loading="loginLoading" @loading="loginLoading = $event" />
  <div v-else-if="loading" class="w-screen h-screen flex justify-center items-center">
    <Spinner />
  </div>
  <App v-else-if="!renderOnboardingFlow" />
  <div v-else class="flex flex-col">
    <div ref="root" v-html="innerHTMLValue"></div>
    <button class="m-auto cursor-pointer hover:opacity-70" @click="logout">Sign Out</button>
  </div>
</template>

<script>
import Vue from 'vue'
import { firestorePlugin } from 'vuefire'
import { init } from 'commandbar'
import { Warrant } from '@warrantdev/vue-warrant'
import Antd, { Spin } from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'

import initializeAuth, { logout } from '@/boot-up/authentication'
import '@/boot-up/jitsu'
import { initXkit } from './boot-up/initServices'
import { loadXkit, loadGooglePlaces, loadPapercups } from '@/boot-up/scriptLoading'
import '@/helpers/prototypes'
import api from '@/helpers/api'
import loadFogbender from './boot-up/fogbender'

Vue.use(firestorePlugin)
Vue.use(Antd)
if (process.env.VUE_APP_WARRANT_CLIENT_KEY)
  Vue.use(Warrant, { clientKey: process.env.VUE_APP_WARRANT_CLIENT_KEY })

export default {
  name: 'Main',
  provide() {
    return { _loadWarrantToken: this.loadWarrantToken }
  },
  components: {
    App: () => import('./App'),
    Login: () => import('./views/Login'),
  },
  data() {
    return {
      renderOnboardingFlow: false,
      innerHTMLValue: '',
      loading: true,
      loginLoading: false,
      showLogin: false,
    }
  },
  async mounted() {
    /* Create Savvy Analytics */
    document.querySelectorAll('savvy-smart-form').forEach(e => e.remove())
    const savvySmartForm = document.createElement('savvy-smart-form')
    savvySmartForm.id = 'W0dlhhoFRqt4ClNnQ0c2'
    document.body.appendChild(savvySmartForm)

    loadFogbender()
    // if (this.$route.path === '/welcome') {
    //   this.$router.replace('/')
    //   this.renderOnboardingFlow = false
    // }

    Spin.setDefaultIndicator({
      indicator: () => {
        return <Spinner></Spinner>
      },
    })

    try {
      /* CommandBar */
      init('87984c40')
      /* Xkit - initialize once user logged in */
      loadXkit()
      /* Other */
      loadGooglePlaces()
      // loadZest()
    } catch (error) {
      console.error(error)
    }
    await this.$nextTick()

    this.init()
  },
  watch: {
    showLogin: {
      handler(v) {
        if (v) {
          this.$router.replace('/login')
          this.renderOnboardingFlow = false
          this.$store.dispatch('clearAllBinds')
        } else this.$router.replace('/')
      },
    },
  },
  methods: {
    init() {
      if (typeof this.unsubscribe === 'function') this.unsubscribe()
      const unsubscribe = initializeAuth(this.onLoggedIn, this.onLoggedOut)
      this.unsubscribe = unsubscribe
    },
    async onLoggedIn() {
      const { data } = await api('/user/serve')
      const { hasGroup, warrantToken, fogbenderToken, isAdmin } = data
      if (warrantToken) this.setWarrant(warrantToken)
      else console.warn('No Warrant Token returned - authorization might not work')
      loadXkit(initXkit)
      this.setFlowRedirect(hasGroup, isAdmin)
      await this.$store.dispatch('initializeUser')
      loadPapercups(this.$store.getters.user)
      if (fogbenderToken) this.initFogbender(fogbenderToken)
      this.showLogin = false
      this.loading = false
      this.loginLoading = false
    },
    onLoggedOut() {
      this.showLogin = true
    },
    async loadWarrantToken() {
      const { data } = await api('/user/warrant')
      if (data) this.setWarrant(data)
      else console.warn('No Warrant Token returned - authorization might not work')
    },
    initFogbender(token) {
      if (!token) console.log('No token for Fogbender found')

      if (window.fogbender) {
        try {
          window.fogbender._load('https://client.fogbender.com')
          const user = this.$store.getters.user
          window.fogbender.setToken({
            userJWT: token,
            userId: user.uid,
            userEmail: user.auth.email,
            userName: user.auth.displayName,
            customerId: user.activeGroupId,
            customerName: user.activeGroupId, // Using active group id here because groups hasnt loaded yet
          })
          console.log('Initialized Fogbender')
        } catch (error) {
          console.error(error)
          console.error('Fogbender failed to init')
        }
      } else console.log('window.fogbender not found')
    },
    setWarrant(token) {
      if (this.$warrant) {
        this.$warrant.setSessionToken(token.token)
        this.$store.commit('setHasWarrantSessionToken', true)
      } else this.$store.commit('setHasWarrantSessionToken', false)
    },
    setFlowRedirect(hasGroup, isAdmin) {
      const flowRedirect = !hasGroup && !isAdmin
      this.renderOnboardingFlow = flowRedirect
      if (flowRedirect) this.loadStaticOnboardingFlow()
      else if (this.$route.path === '/welcome') this.$router.replace('/')
      else if (this.$route.path === '/login') this.$router.replace('/')
      else if (window.location.hostname === 'integrations.trysavvy.com')
        this.$router.replace('/integrations')
    },
    async logout() {
      await logout()
      this.$router.replace('/login')
      this.renderOnboardingFlow = false
    },
    loadStaticOnboardingFlow() {
      const flowScript = document.createElement('script')
      const createFlowTag = () => {
        this.$router.replace('/welcome')
        this.innerHTMLValue = `<savvy-flow id="XJOWEJu014q01R4QDN43"></savvy-flow>`
      }
      flowScript.onload = createFlowTag
      flowScript.src = 'https://flows.trysavvy.com/flows/savvy-flow.min.js'
      document.head.appendChild(flowScript)
    },
  },
}
</script>
